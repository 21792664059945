export function authUser(token) {
    return (dispatch) => {
        if (process.browser) {
            localStorage.setItem('authUserHash', token);
        }
        dispatch(authUserAction(token));
    };
}

export function authUserAction(token) {
    return {
        type: 'USER_LOGIN_TOKEN',
        payload: token,
    };
}
