import { setIn } from 'final-form';
import createDecorator from 'final-form-focus';
import JsCookie from 'js-cookie';
import Head from 'next/head';
import Link from 'next/link';
import Router from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';

import { authUser } from '@actions/auth/authUser';
import { updateCurrentUser } from '@actions/auth/updateCurrentUser';
import { setPage } from '@actions/page/page';
import { Button, createNotification } from '@components';
import { notificationStatuses } from '@constants';
import { PasswordInput, StringInput } from '@form-components';
import { required } from '@validators';

import { SiteLayout } from '../../../layouts';

import './login-page.scss';

const focusOnErrors = createDecorator();

const LoginPage = () => {
    const dispatch = useDispatch();

    const formRef = useRef();

    const userAuth = useCallback(
        async values => {
            let response = await fetch('/api/site/auth/login/form', {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            switch (response.status) {
                case 200:
                    let { token } = await response.json();

                    dispatch(authUser(token));
                    dispatch(updateCurrentUser());
                    JsCookie.set('Authorization', 'Bearer ' + token);
                    Router.push('/');
                    break;
                case 422:
                    return setIn({}, 'login', 'Неверный логин или пароль');
                default:
                    createNotification('Ошибка на сервере', notificationStatuses.error);
                    break;
            }
        },
        [dispatch],
    );

    useEffect(() => {
        dispatch(setPage('/login'));
    }, [dispatch]);

    return (
        <div>
            <Head>
                <title>Авторизация</title>
            </Head>
            <div className='login__wrap'>
                <div className='login__container'>
                    <section className='login'>
                        <div className='login__header'>
                            <h1 className='login__title'>Авторизация</h1>
                        </div>

                        <Form
                            onSubmit={userAuth}
                            decorators={[focusOnErrors]}
                            render={({ handleSubmit, form }) => {
                                formRef.current = form;

                                return (
                                    <form className='login__form' onSubmit={handleSubmit} noValidate={true}>
                                        <div className='login__form-item login__form-input'>
                                            <Field
                                                name='login'
                                                component={StringInput}
                                                type='text'
                                                placeholder='Логин'
                                                validate={required()}
                                                required={true}
                                                secondary={true}
                                            />
                                        </div>
                                        <div className='login__form-item login__form-input'>
                                            <Field
                                                name='password'
                                                component={PasswordInput}
                                                placeholder='Введите пароль'
                                                validate={required()}
                                                required={true}
                                                secondary={true}
                                            />
                                        </div>
                                        <div className='login__form-forgot'>
                                            <Link href='/site/forgot-password-page' as='/forgot-password'>
                                                Забыли пароль?
                                            </Link>
                                        </div>
                                        <div className='login__form-item submit'>
                                            <Button className='login__log-in-btn'>Войти</Button>
                                        </div>
                                        <div className='login__form-registration'>
                                            <p className='login__form-registration__text'>Ещё не зарегистрированы?</p>
                                            <Link href='/site/register-page' as='/register'>
                                                <Button view='primary-outline' size='medium' className='login__form-registration__btn'>
                                                    Регистрация
                                                </Button>
                                            </Link>
                                        </div>
                                    </form>
                                );
                            }}
                        />
                    </section>
                </div>
            </div>
        </div>
    );
};

LoginPage.layout = {
    component: SiteLayout,
    isMinimalHeader: true,
};

export default connect(null)(LoginPage);
